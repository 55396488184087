@import "colors";

.dropzone {
  @include unselectable();
  width: 100%;
  height: 25rem;
  border: 2px dashed get-color(elevation-2, dark);
  color: get-color(grey, light) !important;
  border-radius: 4px;
  cursor: pointer;

  &:not(.has-file) {
    opacity: 0.6;
    transition: 0.15s all ease-in-out;

    &:hover {
      transition: 0.15s all ease-in-out;
      opacity: 1;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  justify-self: center;
  align-self: center;
  text-align: center;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  line-height: 1.4rem;
  color: get-color(elevation-3, dark);
  flex-direction: column;

  & .icon {
    font-size: 8rem;
    line-height: 5rem;
    position: relative;
  }

  & > span {
    position: relative;
    margin-top: 1rem;
  }

  & .dark-mode {
    border-color: get-color(grey, dark) !important;
    color: get-color(grey, dark) !important;
  }

  & .image-preview-layer {
    width: 85%;
    height: 85%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.has-file {
    & .image-preview-layer {
      &:after {
        opacity: 0;
      }
    }

    & .icon.delete-preview {
      position: absolute;
      background: get-color(danger, dark);
      border: 1px solid get-color(danger-highlight, dark);
      top: 0;
      right: 0;
      z-index: 42;
      display: block;
      color: get-color(danger-highlight, dark);
      padding: 8px;
      line-height: 1;
      font-size: 2rem;
      font-weight: 100;
      opacity: 0.7;

      &:hover {
        cursor: pointer;
        opacity: 1;
        background: get-color(danger-highlight, dark);
        color: get-color(danger, dark);
      }
    }

    &:hover {
      &:after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: get-color(elevation-2, dark) !important;
        opacity: 0.5;
        transition: 0.2s opacity ease-in-out;
      }
    }

    & .preview-video {
      position: absolute;
      top: 0;
      left: 0;
      background: get-color(elevation-1, dark);
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
    }

    & .icon {
      transition: 0.2s color ease-in-out;
      color: transparent;
    }

    & > span {
      transition: 0.2s color ease-in-out;
      color: transparent;
    }
    &:hover {
      cursor: pointer;
      & .icon:not(.delete-preview) {
        color: get-color(highlight, dark) !important;
        z-index: 1;
      }
      & > span {
        color: get-color(highlight, dark) !important;
        z-index: 1;
      }
    }
  }
}

.upload-thumbnail {
  max-width: 100%;
  .workflow-file & {
    max-height: 25rem;
  }
}
.draggable {
  margin-bottom: 1rem;
  position: relative;

  &::before {
    content: "";
    font-family: "Siemens UI";
    position: absolute;
    left: 0;
    top: 50%;
    width: 3.7rem;
    height: auto;
    z-index: 1;
    transform: translate(0, -50%);
    text-align: center;
    opacity: 0.2;
    font-size: 1.8rem;
  }
  &:hover {
    &::before {
      opacity: 0.35;
    }
  }
}
.drop-container-buttons {
  position: absolute;
  right: -15px;
  top: -15px;
  & > button {
    margin: 0 !important;
    font-size: 2rem;
    text-align: center;
    padding: 0.5rem !important;
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    line-height: 2rem;

    & > i {
      padding: 0;
    }
  }
}

.drop-container {
  min-height: 10rem;
}
