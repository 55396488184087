.accordion {
  & .mediafile-preview {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;

    & .media-player video {
      max-height: 50rem;
      //max-width: 50rem;
    }
  }

  & .dropzone {
    & .media-player video {
      max-height: 24rem;
      max-width: 24rem;
    }
  }
  & .accordion-button {
    background: transparent;
    border: 0;
    color: #fff;
    font-size: 2rem;
    padding-left: 4.5rem;
    width: 100%;
    height: 100% !important;
    text-align: left;
    line-height: 100%;
    &:after {
      content: "";
      font-family: "Siemens UI";
      position: absolute;
      right: 0;
      top: 50%;
      height: auto;
      width: 5rem;
      transform: translate(0, -50%);
      font-size: 2.5rem;
      opacity: 0.7;
    }
    &:not(.collapsed):after {
      content: "";
    }
  }

  & .accordion-item {
    margin: 1rem 0;
    height: 100%;

    & .accordion-header {
      padding: 0;
      height: 3rem;

      & .title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 35vw;
        display: block;

        @include large {
          max-width: 60%;
        }
      }
    }

    & .workflow-description {
      white-space: pre-wrap; /* CSS3 */
      white-space: -moz-pre-wrap; /* Firefox */
      white-space: -pre-wrap; /* Opera <7 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word;
      & p {
        white-space: break-spaces;
      }
    }
  }

  & .accordion-body {
    background-color: get-color(elevation-1, dark);
    padding: 2rem;

    & .drop-container {
      margin: 1rem 0;
    }

    @include small-smartphone {
      padding: 1rem;
    }

    @include smartphone {
      padding: 1rem;
    }

    & .workflow-step {
      @include small-smartphone {
        padding: 0 2rem;
      }

      @include smartphone {
        padding: 0 2rem;
      }

      & form {
        width: 100%;
      }
    }
  }
}
.workflow-step > .row {
  padding: 1rem 0;

  @include small-smartphone {
    padding: 0;
    flex-direction: column !important;
  }

  @include smartphone {
    padding: 0;
    flex-direction: column !important;
  }

  & > * {
    padding: 1rem;
    max-width: 100%;
    width: 100%;
    flex: 1;
  }
}

.drop-container {
  & > .row {
    max-width: 100%;
  }
}

.workflow-step-button {
  position: absolute;
  right: 70px;
  top: 0;
  height: 100%;
  justify-content: revert;
  align-content: center;
  display: flex;

  & > button {
    align-self: center;
  }
}
