@import "colors", "base", "texts";

// ######################################
//             INPUTS
// ######################################

input[type="text"],
input[type="text"],
textarea,
.custom-file-label {
  font-size: 1.75rem !important;
  height: 4rem;
  background: get-color(input-bg, light) !important;
  border: 1px solid get-color(input-grey, light) !important ;
  border-radius: 2px !important;
  // padding-inline-end: 2em;

  &:hover {
    background: get-color(highlight-2, light) !important;
    border-color: get-color(primary, light) !important;
  }
  &:focus {
    background: get-color(highlight-2, light) !important;
    border-color: get-color(primary, light) !important;
  }

  .dark-mode & {
    border-color: get-color(input-grey, dark) !important;
    background: get-color(input-bg, dark) !important;

    &:hover {
      background: get-color(highlight-2, dark) !important ;
      border-color: get-color(primary, dark) !important;
    }
    &:focus {
      background: get-color(highlight-2, dark) !important;
      border-color: get-color(primary, dark) !important;
    }

    &.error {
      border-color: get-color(danger-highlight, dark) !important;
    }
  }
}

textarea {
  height: 100% !important;
}

.custom-file {
  height: 4rem;
  .custom-file-input {
    height: 100%;
  }
  .custom-file-input ~ .custom-file-label::after {
    height: auto;
    background: get-color(input-bg, light) !important;
    .dark-mode & {
      background: get-color(input-bg, dark) !important;
    }
  }
  .custom-file-label {
    line-height: 2rem;
    display: flex;
    align-items: center;

    &:after {
      border-color: get-color(primary, light) !important;
      .dark-mode & {
        border-color: get-color(primary, dark) !important;
      }
    }
  }
  &:hover {
    & .custom-file-label,
    & .custom-file-label:after {
      background: get-color(highlight-2, light) !important;
      border-color: get-color(primary, light) !important;
      .dark-mode & {
        background: get-color(highlight-2, dark) !important;
        border-color: get-color(primary, dark) !important;
      }
    }
  }
}

// ######################################
//             CHECKBOX
// ######################################

.form-check {
  padding-left: 10px;
}

.form-check input[type="checkbox"],
.form-check input[type="radio"] {
  position: absolute;
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
}
.form-check input[type="checkbox"] {
  &:checked {
    & ~ span.checkbox:before {
      font-family: "Siemens UI" !important;
      content: "";
    }
  }
}

.form-check span.checkbox {
  position: relative;

  &:before {
    $size: 24px;
    content: " ";
    position: absolute;
    left: 0;
    width: $size;
    height: $size;
    background: get-color(nuance, light);
    border: 2px solid get-color(secondary, light) !important;
    line-height: 16px;
    padding: 2px;
    font-size: 16px;

    .dark-mode & {
      background: get-color(nuance, dark);
      border-color: get-color(secondary, dark) !important;
    }
  }

  & ~ label {
    @include unselectable();
    position: relative;
    margin-left: 38px;
    opacity: 0.8;
    line-height: 16px;
  }
}

select {
  border-color: get-color(primary, dark) !important;
  background: transparent !important;
  line-height: 1.5;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  color: get-color(primary, dark) !important;
  font-size: 12px;
  height: 3rem;
}

.form-check {
  position: relative;
  &:hover {
    cursor: pointer;
    & span.checkbox:before {
      background: get-color(highlight-2, light);
      color: get-color(primary, light);
      border-color: get-color(primary, light) !important;
      .dark-mode & {
        background: get-color(highlight-2, dark);
        color: get-color(primary, dark);
        border-color: get-color(primary, dark) !important;
      }
    }
    & label {
      cursor: pointer;
      opacity: 1 !important;
    }
  }
}

// ######################################
//             RADIO
// ######################################
.form-radio {
  & span:before {
    border-radius: 100%;
  }
}

.form-check input[type="radio"] {
  & ~ span.checkbox:before {
    $size: 24px;
    width: $size;
    height: $size;
  }
  &:checked {
    & ~ span.checkbox:after {
      top: 1px;
      content: "";
      position: absolute;
      left: 7px;
      top: 4px;
      background: get-color(primary, light);
      width: 10px;
      height: 10px;
      border-radius: 100%;
      line-height: 30%;
      padding-left: 1px;
    }
  }
}

.form-group {
  margin-bottom: 2rem;
  .list-item & {
    margin: 0;
  }
  @include small-smartphone {
    flex-direction: column;
    & > * {
      flex: 1;
      max-width: 100%;
    }
  }
  @include smartphone {
    flex-direction: column;
    & > * {
      flex: 1;
      max-width: 100%;
    }
  }
}

.input-container {
  width: 100%;
  position: relative;
}
