@import "colors", "base";

.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 4rem;

  @include smartphone {
    display: flex;
  }

  & .step {
    position: relative;
    display: table-cell;
    text-align: center;
    font-size: 1.2rem;
    color: get-color(elevation-3, dark);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 100;

    @include smartphone {
      &:not(.is-active) {
        display: none;
        &:after {
          display: none;
        }
        &:before {
          display: none;
        }
      }
    }
    &:before {
      content: attr(data-step);
      display: block;
      margin: 0 auto;
      background: get-color(background, dark);
      border: 2px solid get-color(elevation-3, dark);
      color: get-color(elevation-3, dark);
      width: 4rem;
      height: 4rem;
      text-align: center;
      margin-bottom: -7.5rem;
      line-height: 3.25rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 100;
      font-size: 2.5rem;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      background: get-color(elevation-3, dark);
      width: 100%;
      height: 1rem;
      top: 1.5rem;
      left: 50%;
    }
    &.is-end:last-child:after {
      display: none !important;
    }
    &.steps-overflow::after {
      content: "";
      position: absolute;
      display: block;
      background: linear-gradient(to right, #474761 0%, rgba(255, 255, 255, 0) 100%);
      width: 100%;
      height: 1rem;
      top: 1.5rem;
      left: 50%;
    }
    &.is-complete {
      color: get-color(elevation-3, dark);
      &:before {
        content: "✓";
        color: get-color(primary, dark) !important;
        background: #000028 !important;
        border: 3px solid get-color(primary, dark) !important;
        font-weight: 800;
      }
      &:after {
        background: get-color(primary, dark) !important;
      }
      &.steps-overflow::after {
        width: 150% !important;
        left: 0;
        background: linear-gradient(to right, get-color(background, dark) 0%, get-color(primary, dark) 50%) !important;
      }
    }
    &.is-active {
      font-size: 1.5rem;
      color: #fff;
      font-weight: 800;

      &:before {
        color: #fff !important;
        border: 3px solid #fff !important;
        background: get-color(primary, dark) !important;
        font-weight: 800;
        letter-spacing: 0;
      }
    }
  }

  #workflows & {
    transform: scale(0.6);
    width: 24rem;

    & .step {
      &:before {
        background: #37374d;
        border-color: #80809e;
        color: #80809e;
        line-height: 3.45rem;
      }
      &.is-active::before {
        background: #80809e !important;
      }
      &:after {
        background: #80809e;
      }
    }

    & .is-active,
    & .is-complete {
      &::before {
        content: attr(data-step);
        border-color: get-color(background, dark) !important;
        color: get-color(background, dark) !important;
      }
    }
    & .is-active {
      &:nth-of-type(1)::before {
        background: #fff !important;
      }
      &:nth-of-type(2)::before {
        background: #80da80 !important;
      }
      &:nth-of-type(3)::before {
        background: #d1b96c !important;
      }
      &:nth-of-type(4)::before {
        background: #d35a5a !important;
      }
    }
    & .is-complete {
      &::before {
        content: attr(data-step);
        // color: #fff !important;
        background: #80809e !important;
      }
      &::after {
        background: get-color(background, dark) !important;
      }
    }
  }
}

.stepper.vertical-onPhone {
  @include smartphone {
    width: 100%;
    & .steps > .step::after {
      content: "";
      position: absolute;
      display: block;
      background: #474761;
      width: 0.5rem;
      height: 100%;
      left: 23px;
      top: 0;
    }

    & .steps > .step::before {
      content: attr(data-step);
      display: block;
      margin: 0 auto !important;
      background: #000028;
      border: 2px solid #474761;
      color: #474761;
      width: 4rem;
      height: 4rem;
      text-align: center;
      line-height: 3.25rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 100;
      font-size: 2.5rem;
    }

    & .steps {
      list-style: none;
      margin: 0;
      padding: 0;
      display: table;
      table-layout: fixed;
      width: 5rem;
      height: auto;
      display: block;
      position: absolute;

      & .step {
        color: transparent;
        display: inline-block;
        height: 9rem;
        width: 5rem;
        font-size: 0;

        &.is-complete {
          &.steps-overflow::after {
            width: 0.5rem !important;
            top: -4rem;
            height: 150%;
            background: linear-gradient(
              to bottom,
              get-color(background, dark) 0%,
              get-color(primary, dark) 50%
            ) !important;
          }
        }
        &::before {
          content: attr(data-step);
          display: block;
          margin: 0 auto !important;
          background: #000028;
          border: 2px solid #474761;
          color: #474761;
          width: 4rem;
          height: 4rem;
          text-align: center;
          line-height: 3.25rem;
          border-radius: 100%;
          position: relative;
          z-index: 1;
          font-weight: 100;
          font-size: 2.5rem;
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          background: #474761;
          width: 0.5rem;
          height: 100%;
          left: 23px;
          top: 0;
        }

        &.steps-overflow {
          &:after {
            background: linear-gradient(to bottom, #474761 0%, rgba(255, 255, 255, 0) 100%);
            width: 0.5rem !important;
            height: 150%;
          }
        }
      }
    }
  }

  @include small-smartphone {
    width: 100%;
    .steps {
      list-style: none;
      margin: 0;
      padding: 0;
      display: table;
      table-layout: fixed;
      width: 5rem;
      height: auto;
      display: block;
      position: absolute;

      & .step {
        color: transparent;
        display: inline-block;
        height: 9rem;
        width: 5rem;
        font-size: 0;

        &.is-complete {
          &.steps-overflow::after {
            width: 0.5rem !important;
            top: -4rem;
            height: 150%;
            background: linear-gradient(
              to bottom,
              get-color(background, dark) 0%,
              get-color(primary, dark) 50%
            ) !important;
          }
        }

        &.steps-overflow {
          &:after {
            background: linear-gradient(to bottom, #474761 0%, rgba(255, 255, 255, 0) 100%) !important;
            width: 0.5rem !important;
            height: 150%;
          }
        }
      }
    }

    & .steps > .step::after {
      content: "";
      position: absolute;
      display: block;
      background: #474761;
      width: 0.5rem;
      height: 100%;
      left: 23px;
      top: 0;
    }

    .steps > .step::before {
      content: attr(data-step);
      display: block;
      margin: 0 auto !important;
      background: #000028;
      border: 2px solid #474761;
      color: #474761;
      width: 4rem;
      height: 4rem;
      text-align: center;
      line-height: 3.25rem;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-weight: 100;
      font-size: 2.5rem;
    }
  }
}
