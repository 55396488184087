@import "colors", "base", "texts";

// ######################################
//             CUSTOM SWITCH
// ######################################
.custom-switch {
  @include unselectable();
  & input:not(:disabled) {
    + .custom-control-label {
      cursor: pointer;
      font-weight: 300 !important;
      padding-left: 2rem;

      &:before {
        color: transparent;
        border-color: transparent;
        border: 0;
        background: get-color(toggle-bg-off, light) !important;
      }

      &:after {
        border-radius: 100%;
        background: get-color(toggle-pin-off, light) !important;
      }

      &:hover {
        &:before {
          background: get-color(toggle-bg-off-hover, light) !important;
        }

        &:after {
          background: get-color(toggle-pin-off-hover, light) !important;
        }
      }

      .dark-mode & {
        &:before {
          background: get-color(toggle-bg-off, dark) !important;
        }

        &:after {
          background: get-color(toggle-pin-off, dark) !important;
        }

        &:hover {
          &:before {
            background: get-color(toggle-bg-off-hover, dark) !important;
          }

          &:after {
            background: get-color(toggle-pin-off-hover, dark) !important;
          }
        }
      }
    }
  }

  & input:disabled {
    ~ .custom-control-label {
      &:before {
        background: get-color(toggle-bg-off-hover, dark) !important;
        opacity: 0.4;
      }

      &:after {
        background: get-color(toggle-pin-off-hover, dark) !important;
        opacity: 0.4;
      }
    }
  }

  & .custom-control-input {
    &:checked ~ .custom-control-label::before {
      background: get-color(toggle-bg-on, light) !important;
      .dark-mode & {
        background: get-color(toggle-bg-on, dark) !important;
      }
    }
    &:checked ~ .custom-control-label::after {
      background: get-color(toggle-pin-on, light) !important;
      .dark-mode & {
        background: get-color(toggle-pin-on, dark) !important;
      }
    }

    &:checked {
      &:hover ~ .custom-control-label::after {
        background: get-color(toggle-pin-on-hover, light) !important;
        .dark-mode & {
          background: get-color(toggle-pin-on-hover, dark) !important;
        }
      }
      &:hover ~ .custom-control-label::before {
        background: get-color(toggle-bg-on-hover, light) !important;
        .dark-mode & {
          background: get-color(toggle-bg-on-hover, dark) !important;
        }
      }
    }
  }
}

.custom-switch .custom-control-input ~ .custom-control-label::after {
  -webkit-transform: scale(1.7) translateX(0rem);
  transform: scale(1.7) translateX(0rem) !important;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: scale(1.7) translateX(1.15rem) !important;
}
.custom-control-input ~ .custom-control-label::before {
  transform: scale(1.9) !important;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 1px);
  left: calc(-2.25rem + 1px);
  width: calc(1rem - 2px);
  height: calc(1rem - 2px);
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 2.1rem !important;
  pointer-events: all;
  top: 0.5rem;
  border-radius: 1.2rem;
}
.custom-switch .custom-control-label::after {
  top: 0.5rem;
  left: -2.6rem;
  width: 0.9rem;
  height: 0.9rem;
}
.custom-control {
  line-height: 0 !important;
}
.custom-control-label {
  vertical-align: bottom !important;
}
