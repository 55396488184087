@import "colors";


.navbar-dark {
  background: get-color(background, light);
  height: 5rem;

  .dark-mode & {
    border: 0;
    background: get-color(background, dark);
    .nav-item {
      .button {
        color: get-color("input-grey", dark) !important;
      }
    }
  }
  .navbar-nav {
    flex-grow: initial;
    flex-shrink: 0;
  }
  .nav-item {
    .button {
      padding-left: 1rem;
      padding-right: 1rem;
      color: get-color("input-grey", light) !important;
    }
  }
  h1 {
    &.navbar-title {
      font-size: 3rem !important;
      flex-grow: 1;
      flex-shrink: 1;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.flag-container {
  cursor: pointer;

  &.inactive {
    cursor: default;
    filter: saturate(0);
  }
}