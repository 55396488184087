@import "colors", "base", "responsive";

.btn {
  @include unselectable();
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  color: #000028;
  border-radius: 0;
  padding: 6px 25px;
  margin-bottom: 0;

  white-space: nowrap;

  &-lg {
    font-size: 18px;
    line-height: 1.3333333;
    padding: 10px 16px;
  }

  &-sm {
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.5;
    padding: 5px 10px;
  }

  &-xs {
    border-radius: 3px;
    font-size: 12px;
    line-height: 1.5;
    padding: 1px 5px;
  }

  .list-item-buttons & {
    @include small-smartphone {
      font-size: 0px !important;
      padding: 0px 6px;
      & .icon {
        font-size: 2rem;
        padding: 0;
      }
    }
  }

  &.btn-round {
    border-radius: 100%;
    width: 4rem;
    height: 4rem;
    align-self: center;
    font-size: 2rem;
  }

  &-cta {
    @include get-gradient(90deg, get-secondary(green, bold) 0%, get-secondary(blue, bold) 100%);

    &:hover,
    &:active {
      @include get-gradient(90deg, get-color(highlight, light) 0%, get-color(highlight, light) 100%);
    }

    .dark-mode & {
      @include get-gradient(90deg, get-secondary(green, bold) 0%, get-secondary(blue, bold) 100%);

      &:hover,
      &:active {
        @include get-gradient(90deg, get-color(highlight, dark) 0%, get-color(highlight, dark) 100%);
      }
    }
  }
  &-primary {
    border: 1px solid get-color(primary, light) !important;
    color: get-color(background, light) !important;
    background: get-color(primary, light) !important;

    &:focus {
      background: get-color(primary, light) !important;
    }
    &:hover,
    &:active {
      background: get-color(hover, light) !important;
    }

    .dark-mode & {
      border: 1px solid get-color(primary, dark) !important;
      color: get-color(background, dark) !important;
      background: get-color(primary, dark) !important;
      &:focus {
        background: get-color(primary, dark) !important;
      }
      &:hover,
      &:active {
        background: get-color(hover, dark) !important;
      }
    }
  }
  &-secondary {
    border: 1px solid get-color(primary, light) !important;
    border-radius: 1px;
    color: get-color(primary, light) !important;
    background: transparent !important;

    &:focus {
      background: get-secondary(basics, transparent) !important;
    }
    &:hover,
    &:active {
      border: 1px solid get-color(hover, light) !important;
      color: get-color(hover, light) !important;
      background: get-color(highlight-2, light) !important;
    }

    .dark-mode & {
      border: 1px solid get-color(primary, dark) !important;
      color: get-color(primary, dark) !important;
      &:focus {
        background: get-secondary(basics, transparent) !important;
      }
      &:hover,
      &:active {
        border: 1px solid get-color(hover, dark) !important;
        color: get-color(hover, dark) !important;
        background: get-color(highlight-2, dark) !important;
      }
    }
  }
  &-tertiary {
    position: relative;
    border: 1px solid get-secondary(basics, transparent) !important;
    color: get-color(primary, light) !important;
    background: get-secondary(basics, transparent) !important;
    padding-left: 25px;
    padding-right: 40px;

    &:focus {
      background: get-secondary(basics, transparent) !important;
    }
    &:hover,
    &:active {
      color: get-color(hover, light) !important;
      background: get-color(highlight-2, light) !important;
    }
    .dark-mode & {
      border: 1px solid get-secondary(basics, transparent) !important;
      color: get-color(primary, dark) !important;
      background: get-secondary(basics, transparent) !important;

      &:focus {
        background: get-secondary(basics, transparent) !important;
      }
      &:hover,
      &:active {
        color: get-color(hover, dark) !important;
        background: get-color(highlight-2, dark) !important;
      }
    }

    &:after {
      content: "›";
      font-size: 20px;
      line-height: 20px;
      position: absolute;
      right: 20px;
      width: 10px;
      height: 10px;
    }
  }
  &-danger {
    border-radius: 1px;
    border-color: get-color(danger-highlight, light) !important;
    color: get-color(danger-highlight, light) !important;
    background: get-color(danger, light) !important;

    &:hover,
    &:active {
      border-color: get-color(hover, light) !important;
      color: get-color(secondary, light) !important;
      background: get-color(danger-highlight, light) !important;
    }

    .dark-mode & {
      border-color: get-color(danger-highlight, dark) !important;
      color: get-color(danger-highlight, dark) !important;
      background: get-color(danger, dark) !important;
      &:hover,
      &:active {
        border-color: get-color(danger, dark) !important;
        color: get-color(background, dark) !important;
        background: get-color(danger-highlight, dark) !important;
      }
      // &:focus {
      //   background: get-color(primary, dark);
      // }
    }
  }
}
.badge-link {
  line-height: normal;
  padding: 0.3rem;
  &:hover {
    border: 0 !important;
  }
}
.badge {
  border: 1px solid;
  border-color: get-color(primary, light) !important;
  color: get-color(primary, light) !important;
  // background: get-color(background, light) !important;
  background: transparent !important;
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  line-height: 22px;
  padding: 0.25em 1em;
  margin: 0 !important;

  &:hover {
    color: get-color(hover, light) !important;
    border-color: get-color(hover, light) !important;
    background: get-color(highlight-2, light) !important;
  }
  .dark-mode & {
    border-color: get-color(primary, dark) !important;
    color: get-color(primary, dark) !important;
    // background: get-color(background, dark) !important;
    background: transparent !important;

    &:hover {
      color: get-color(hover, dark) !important;
      border-color: get-color(hover, dark) !important;
      background: get-color(highlight-2, dark) !important;
    }
  }
  &.icon {
    border-radius: 100%;
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
    &-cta {
      border: 0;
      color: #000028 !important;
      .dark-mode & {
        @include get-gradient(90deg, get-secondary(green, bold) 0%, get-secondary(blue, bold) 100%);

        &:hover {
          color: #000028 !important;
          @include get-gradient(90deg, get-color(highlight, dark) 0%, get-color(highlight, dark) 100%);
        }
      }
    }
  }
}

.add-button {
  @include small-smartphone {
    margin-right: 0 !important;
    margin-top: 1rem !important;
  }
}
