@import "colors";

.warning-dialogue {
  position: relative;
  background: get-color(danger, dark);
  background: #331131;
  padding: 1rem;
  display: block;

  &::before {
    left: -1rem;
    height: 100%;
    top: 0;
    width: 0.3rem;
    background: get-color(danger-highlight, dark);
    content: "";
    position: absolute;
  }
}
