@import "colors", "base";

.user-panel a {
  border: 0;
  color: get-color(primary, light) !important;
  .dark-mode & {
    color: get-color(primary, dark) !important;
  }
  &:hover {
    color: get-color(hover, light) !important;
    border-bottom: 1px solid get-color(hover, light);
    .dark-mode & {
      color: get-color(hover, dark) !important;
      border-bottom: 1px solid get-color(hover, dark);
    }
  }
}
.content-wrapper a {
  border: 0;
  color: get-color(primary, light) !important;
  .dark-mode & {
    color: get-color(primary, dark) !important;
  }
  &:visited {
    color: get-color(visited, light) !important;
    border: 0;
    .dark-mode & {
      color: get-color(visited, dark) !important;
    }
  }
  &:hover {
    color: get-color(hover, light) !important;
    border-bottom: 1px solid get-color(hover, light);
    .dark-mode & {
      color: get-color(hover, dark) !important;
      border-bottom: 1px solid get-color(hover, dark);
    }
  }
}
a.no-link {
  &:hover {
    border: 0 !important;
  }
}
//  breadcrumbs
.breadcrumb-item {
  & + .breadcrumb-item {
    line-height: 155% !important;
    &::before {
      color: get-color(grey, dark) !important;
      font-size: 22px;
      content: "›";
    }
  }
}
.breadcrumb {
  align-self: center;
}
