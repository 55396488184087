@import "colors", "base";

.login-container {

  background-color: get-color(elevation-1, dark);
  max-width: 400px;
  border-radius: 8px;
  min-height: 0px !important;
  margin-top: 100px;
  padding: 24px;

  .login-logo {
    margin: 1rem auto 3rem auto;
  }

  .input-group-text {
    background-color: get-color(elevation-2, dark);
    border-width: 0;
    font-size: 1em;
    padding: 0 15px;
  }
  .input-text {
    border-width: 0;
  }

}