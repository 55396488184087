@import "responsive";

@import "../../node_modules/font-face-generator/mixin";

@include font-face(
  (
    "Siemens Sans": (
      400: (
        normal: "SiemensSans_Prof_Roman",
      ),
      600: (
        normal: "SiemensSans_Prof_Bold",
        italic: "SiemensSans_Prof_BoldItalic",
      ),
      800: (
        normal: "SiemensSans_Prof_Black",
        italic: "SiemensSans_Prof_BlackItalic",
      ),
    ),
  ),
  $types: "woff2" "woff" "ttf" "eot",
  $path: "../../src/siemens-styles/fonts/"
);

@include font-face(
  $fonts: (
    "Siemens UI": (
      400: (
        normal: "Siemens_UI_Icons_Webfont-v391",
      ),
    ),
  ),
  $types: "woff" "ttf" "eot",
  $path: "../../src/siemens-styles/fonts/"
);

$font-stack: "Siemens Sans";

* {
  font-family: $font-stack !important;
  // font-family: "Arial";
  // color: get-color(secondary, light);
  // .dark-mode & {
  //   color: get-color(secondary, dark);
  // }
}

option {
  font-family: $font-stack !important;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  display: inline-block !important;
  text-transform: capitalize;
  // &:first-letter {
  //   text-transform: capitalize;
  // }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

h1 {
  color: get-color(secondary, light);
  .dark-mode & {
    color: get-color(secondary, dark);
  }
  position: relative;
  font-size: 6rem !important;
  line-height: 156% !important;
  letter-spacing: -0.5px;
  font-weight: 400;
  // padding-left: 20px;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   left: -10px;
  //   height: calc(100% - 20px);
  //   top: 10px;
  //   width: 6px;
  //   background: linear-gradient(180deg, #00ffb9 0%, #00e6dc 100%);
  // }
}

h2 {
  color: get-color(secondary, light);
  .dark-mode & {
    color: get-color(secondary, dark);
  }
  position: relative;
  // padding-left: 20px;
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  line-height: 130%;

  /* or 130% */
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   left: -10px;
  //   height: calc(100% - 20px);
  //   top: 10px;
  //   width: 6px;
  //   background: linear-gradient(180deg, #00ffb9 0%, #00e6dc 100%);
  // }
}

h3 {
  color: get-color(secondary, light);
  .dark-mode & {
    color: get-color(secondary, dark);
  }
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 128%;

  /* or 128% */
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
}

h4 {
  color: get-color(secondary, light);
  .dark-mode & {
    color: get-color(secondary, dark);
  }
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 3rem;
  line-height: 119%;

  /* or 119% */
  display: flex;
  align-items: center;
}

h5 {
  color: get-color(secondary, light);
  .dark-mode & {
    color: get-color(secondary, dark);
  }
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  line-height: 133%;
}

h6 {
  color: get-color(secondary, light);
  .dark-mode & {
    color: get-color(secondary, dark);
  }
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 140%;

  /* or 140% */
  display: flex;
  align-items: center;
}

.topline {
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 122%;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.intro {
  // color: get-color(secondary, light);
  // .dark-mode & {
  //   color: get-color(secondary, dark);
  // }
  font-family: "Siemens Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
}

.icon {
  font-family: "Siemens UI" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  &-lg {
    font-size: 20px;
  }
  &-hg {
    font-size: 4rem;
  }
  button & {
    font-style: normal;
    padding-right: 5px;
  }
}

p {
  font-size: 1.75rem !important;
  line-height: 156% !important;
}

.breadcrumb-container {
  @include small-smartphone {
    display: none;
  }

  @include smartphone {
    display: none;
  }
}

.content-header {
  & > * {
    @include smartphone {
      flex-direction: column-reverse !important;
    }
    @include tablet {
      flex-direction: column-reverse !important;

      & .breadcrumb-container {
        justify-content: flex-start;
        padding-bottom: 3rem;
      }
    }
  }
}
.subtitle {
  opacity: 0.5;
  font-size: 1.1rem;
}
