.media-player {
  width: auto;
  height: auto;
  position: relative;
  text-align: center;

  & .control {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    transition-delay: 3s;


    & > *:after {
      @include smartphone {
        font-size: 10vw;
      }
      @include small-smartphone {
        font-size: 15vw;
      }
      font-size: 6vw;
      color: #fff;
      z-index: 1;
      cursor: pointer;
    }
    & .icon {
      display: flex;
      align-self: center;
    }

    & .control-play:after {
      content: "";
    }
    & .control-pause:after {
      opacity: 0;
      transition: opacity 0.2s ease-out;
      content: "";
      &:hover {
        opacity: 1;
        transition: opacity 0.2s linear;
      }
    }
    & .control-replay:after {
      opacity: 0;
      transition: opacity 0.2s ease-out;
      content: "";
      &:hover {
        opacity: 1;
        transition: opacity 0.2s linear;
      }
    }
    & .control-end:after {
      opacity: 0;
      transition: opacity 0.2s ease-out;
      content: "";
      &:hover {
        opacity: 1;
        transition: opacity 0.2s linear;
      }
    }
  }

  & video {
    max-height: 200px;
  }
}
.workflow-step {
  & .row {
    flex: 1;
    flex-direction: row;
  }
}

.description {
  & .description-container {
    position: relative;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: get-color(background, dark);
      opacity: 0.7;
      left: 0;
      top: 0;
    }
    &.success:after {
      position: absolute;
    }
    &.processing:after {
      position: absolute;
    }
  }
}
