@use "sass:map";
@import "dimensions";
@import "colors";

/**
* Generic containers, that can be used throughout the application.
 */

.base-container {
  padding: $padding-default;
  border-radius: $border-radius;
  margin: $margin-default;
}

$sel: '';
@for $i from 1 through 3 {
  $sel: if($i == 1, ".base-container", selector-nest($sel, ".base-container")) !global;
  #{$sel} {
    background-color: map.get(get-color(elevation, dark), $i);
    &.selected-container {
      background-color: lighten(map.get(get-color(elevation, dark), $i), 15);
    }
  }
}