@import "colors", "base";

.table-striped tbody tr:nth-of-type(2n + 1) {
  .dark-mode & {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

// .table th,
// .table td {
//   vertical-align: middle;
//   .dark-mode & {
//     border-top-color: get-color(background-alt, dark) !important;
//   }
// }

// .dark-mode .table thead th {
//   border-bottom-color: get-color(background-alt, dark) !important;
// }
.table-hover tbody tr:hover {
  // color: #dee2e6;

  .dark-mode & {
    background-color: get-color(background-alt, dark) !important;
    border-color: get-color(background-alt, dark) !important;
  }
}

.table {
  & p {
    margin: 0 !important;
  }

  & thead th {
    .dark-mode & {
      border-bottom-color: get-color(background-alt, dark) !important;
    }
  }

  & th,
  & td {
    vertical-align: middle;
    .dark-mode & {
      border-top-color: get-color(background-alt, dark) !important;
    }
  }
}
