.remove-item {
  position: absolute;
  right: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  opacity: 0.6;
  top: 0.5rem;
  line-height: 1.5rem;

  &:hover {
    opacity: 1;
  }
}
