.flex{
  display: flex;
}
.flex-row{
  flex-direction: row;
}
.flex-column{
  flex-direction: column;
  flex-wrap: wrap;
}
.flex-children>div{
  display: flex;
  flex: 1 1 0;
}
.flex-children .card-body{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex>.flex-item{
  flex: 1 1 0;
}
.flex-item.flex-33{
  flex: 0 0 33%;
}
.flex-fullwidth{
  flex: 1 1 100%;
}
.flex-end{
  justify-content: flex-end;
}

.badge.group {
  padding-right: 35px;
  position: relative;
}
.badge.group>.remove {
  position: absolute;
  right: 8px;
  width: 20px;
  border-radius: 10px;
  height: 100%;
  top: 0;
  line-height: 30px;
  font-size: 14px;
  font-weight: 600;
}
.badge.group>.remove:hover {
  /* background-color: rgba(0,0,0,0.3); */
}

