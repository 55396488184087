@import "colors", "base";

.nav-tabs {
  & .nav-item {
    font-weight: 600;
    line-height: 1.5rem;
    border: 0px !important;
    line-height: 6rem;
    border: 0;

    @include smartphone {
      line-height: 4rem;
    }
    @include small-smartphone {
      line-height: 1.5rem;
      padding: 2rem 0;
    }

    & button {
      background: transparent;
      color: #fff;
      width: 100%;
      border: 0px !important;
      box-shadow: inset 0px -2px 0px get-color(tab-grey, light);

      &.active {
        border: 0px !important;
        background-color: transparent !important;
        box-shadow: inset 0px -2px 0px get-color(primary, light);
        .dark-mode & {
          box-shadow: inset 0px -2px 0px get-color(primary, dark);
        }
      }

      &:hover {
        border: 0 !important;
        background-color: get-color(hover-2, light) !important;
        box-shadow: inset 0px -2px 0px get-color(hover, light);
        .dark-mode & {
          background-color: get-color(hover-2, dark) !important;
          box-shadow: inset 0px -2px 0px get-color(hover, dark);
        }
      }
      .dark-mode & {
        color: get-primary(white) !important;
        box-shadow: inset 0px -2px 0px get-color(tab-grey, dark);
      }
    }
  }
}
.tab-content {
  min-height: 55rem;
}
