.list-container {
  & .list-item-container {
    &.clickable {
      cursor: pointer;

      &:hover {
        border-radius: 4px;
        background-color: get-color(elevation-2, light) !important;
        .dark-mode & {
          background-color: get-color(elevation-2, dark) !important;
        }
      }
    }
    position: relative;
    padding-left: 3rem;
    display: grid;
    grid-template-rows: 1;
    grid-template-columns: 1% 30% 49% auto;
    align-content: center;

    & .list-item:last-child {
      justify-self: end;
    }
    & .list-item {
      display: flex;
      align-items: center;
    }

    @include small-smartphone {
      margin-bottom: 0.5rem !important;
      padding: 0px;
    }
    @include smartphone {
      padding: 2px;
    }

    @include tablet {
      padding: 0.5rem;
    }
    @include desktop {
      padding: 1rem;
    }

    div[class*="marked-"] {
      &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 0.7rem;
        left: 0;
        top: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: transparent;
      }
    }
    // ADD MORE FRONT STRIPE COLORS FOR LIST ITEMS HERE
    &.marked {
      &-primary {
        &::before {
          background: get-color(primary, light) !important;
          .dark-mode & {
            background: get-color(primary, dark) !important;
          }
        }
      }
      &-secondary {
        &::before {
          background: get-color(secondary, light) !important;
          .dark-mode & {
            background: get-color(secondary, dark) !important;
          }
        }
      }
    }
    & .info-box {
      border-radius: 4px;
      background-color: get-color(sidebar, light) !important;

      .dark-mode & {
        background-color: get-color(sidebar, dark) !important;
      }
    }

    & .info-box-content {
      display: grid;
      grid-template-rows: 1;
      grid-template-columns: 1rem repeat(3, 1fr);
      align-items: center;
      padding: 0 3rem;

      & .flex-end {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-end;
      }
    }
  }

  & .list-header {
    color: get-color(grey, light) !important;
    font-weight: 700;
    font-size: 1.4rem;
    .dark-mode & {
      color: get-color(grey, dark) !important;
    }
  }
  & .title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    line-height: 2rem;
    text-overflow: ellipsis;
    overflow: hidden;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    & .title {
      white-space: nowrap;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .subtitle {
      font-size: 1.1rem !important;
      color: get-color(grey, light) !important;
      .dark-mode & {
        color: get-color(grey, dark) !important;
      }
    }
  }
}

.list-item-container {
  .draggable & {
    margin-bottom: 0;
  }
  & .list-item {
    line-height: 4.1rem;
    flex-wrap: wrap;
    padding: 0 1rem;

    .draggable & {
      padding-left: 4rem;
      @include smartphone {
        padding: 0 3.5rem;
      }
      @include tablet {
        padding: 0 3.5rem;
      }
    }
    @include small-smartphone {
      padding: 0 0.5rem !important;
    }
  }
  & .list-item-buttons {
    @include small-smartphone {
      flex-wrap: nowrap;
    }
  }
  #organizations & {
    grid-template-columns: 5% 20% 20% 10% 10% 10% auto;
    @include small-smartphone {
      grid-template-columns: 5% 70% auto;

      .list-item:not(:last-of-type):nth-of-type(n + 3) {
        display: none;
      }
    }
  }
  #group-users & {
    grid-template-columns: 5% 40% auto;
  }
  #workplaces & {
    grid-template-columns: 5% 40% auto;
    @include small-smartphone {
      grid-template-columns: 11% 50% auto;
    }
  }
  #workflows & {
    grid-template-columns: 5% 40% 20% auto;

    @include tablet {
      grid-template-columns: 7% 45% 10% auto;
    }
    @include smartphone {
      grid-template-columns: 7% 50% 7% auto;
    }
    @include small-smartphone {
      grid-template-columns: 11% 50% 0% auto;
    }
  }
  #groups & {
    grid-template-columns: 5% 40% 10% auto;
    @include small-smartphone {
      grid-template-columns: 5% 70% auto;

      .list-item:nth-of-type(3) {
        display: none;
      }
    }
  }
  #users & {
    grid-template-columns: 5% 25% auto auto;

    @include small-smartphone {
      grid-template-columns: 5% 70% auto;

      .list-item:nth-of-type(3) {
        display: none;
      }
    }
  }
  #ar & {
    grid-template-columns: 15% 40% 10% auto;

    @include small-smartphone {
      grid-template-columns: 15% 50% 5% auto;
    }
    @include smartphone {
      grid-template-columns: 15% 44% 7% auto;
    }
  }
  #icons & {
    grid-template-columns: 15% 40% 10% auto;
    @include small-smartphone {
      grid-template-columns: 20% 40% 10% auto;
    }

    @include tablet {
      grid-template-columns: 16% 38% 5% auto;
    }

    @include smartphone {
      grid-template-columns: 15% 31% 5% auto;
    }
  }
  #patterns & {
    grid-template-columns: 5% 40% 10% auto;
  }
  #envs & {
    grid-template-columns: 5% 40% auto;

    @include small-smartphone {
      grid-template-columns: 8% 55% auto;
    }
  }
}
.dndable {
  cursor: pointer;
  & .info-box {
    &:hover {
      border-radius: 4px;
      background-color: get-color(elevation-2, light) !important;
      .dark-mode & {
        background-color: get-color(elevation-2, dark) !important;
      }
    }
  }
}

.list-item-container {
  &.elevation-2 {
    background-color: get-color(elevation-2, light) !important;
    .dark-mode & {
      background-color: get-color(elevation-2, dark) !important;
    }

    &.dndable {
      &:hover {
        background-color: get-color(elevation-3, light) !important;
        .dark-mode & {
          background-color: get-color(elevation-3, dark) !important;
        }
      }
    }
  }
}
