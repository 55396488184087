@import "colors";

.group-detail-name > .icon {
  justify-self: flex-end;
  align-self: flex-end;
  padding-right: 2rem;
}
.group-detail-name {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  line-height: 2rem;

  &:hover {
    background-color: get-color(highlight-2, light) !important;

    .dark-mode & {
      background-color: get-color(highlight-2, dark) !important;
    }
  }
}
