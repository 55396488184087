.file {
  margin: 1rem;

  & .file-header.card-header {
    text-align: center;
  }

  & .icon-bg {
    font-size: 7rem;
  }
  & .file-icon-bar {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;

    & .icon-remove {
      width: 3rem;
      height: 3rem;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }
}
