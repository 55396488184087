@import "colors", "base";
.modal-dialog {
  max-width: 600px;
}
.modal-content {
  padding: 2rem 3rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3), 0px 2px 2px rgba(0, 0, 0, 0.3), 0px 4px 4px rgba(0, 0, 0, 0.3),
    0px 8px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;

  @include small-smartphone {
    margin: 0 1rem;
    padding: 1rem !important;
  }

  .warning & {
    border-left: 0.6rem solid get-color(danger-highlight, light) !important;

    .dark-mode & {
      border-left: 0.6rem solid get-color(danger-highlight, dark) !important;
    }

    & .warning-dialog {
      &.warning {
        font-weight: 800;
        display: flex;
        margin-top: 3rem;
        &::before {
          content: "\e936";
          padding-right: 1rem;
          font-family: "Siemens UI";
          font-weight: 100;
          font-size: 2.5rem;
        }
      }
      & .bold {
        font-weight: 700;
        letter-spacing: 0.4px;
      }
    }
  }

  .dark-mode & {
    background: get-color(background-alt-2, dark) !important;
    border: 1px solid get-color(nuance, dark);
  }
  & .close {
    .dark-mode & {
      color: get-color(primary, dark);
    }
  }
  & .modal-body {
    & p {
      font-size: 1.6rem !important;
    }
  }

  & .modal-title {
    font-family: "Siemens Sans";
    font-style: normal;
    font-weight: 900;
    font-size: 2rem;
    line-height: 140%;
  }
  & .modal-header {
    border: 0 !important;
    .dark-mode & {
      border-color: get-color(nuance, dark);
    }
  }
  & .modal-footer {
    display: flex;
    border: 0;

    @include small-smartphone {
      justify-content: space-between;
      padding: 0;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
    @include smartphone {
      justify-content: space-between;
      padding: 0;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
  }

  .close & span {
    opacity: 0.8 !important;
    font-size: 4rem;
    font-weight: 100;
    position: absolute;
    right: 4rem;
    top: 2rem;
    padding: 0;
    color: #66667e !important;
    line-height: 100%;
    opacity: 1 !important;

    @include small-smartphone {
      right: 1rem;
      top: 0rem;
    }
    .dark-mode & {
      color: get-color(grey, dark) !important;
    }

    & span:hover {
      opacity: 1 !important;
    }
  }

  .btn-close {
    background: none;
    border: 0;
    &:after {
      content: "";
      opacity: 0.7;
      font-size: 2.5rem;
      font-weight: 100;
      position: absolute;
      right: 4rem;
      top: 2rem;
      padding: 0;
      color: #66667e !important;
      line-height: 100%;
      font-family: "Siemens UI";

      @include small-smartphone {
        right: 1rem;
        top: 1rem;
      }
      .dark-mode & {
        color: get-color(grey, dark) !important;
      }
    }

    &:hover {
      &:after {
        opacity: 1 !important;
      }
    }
  }
}

.modal-backdrop {
  opacity: 0.7 !important;
  background-color: get-color(backdrop, light) !important ;

  .dark-mode & {
    background-color: get-color(backdrop, dark) !important;
  }
}
