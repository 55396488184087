@import "colors", "base";

.main-footer {
  border: 0;
  background: get-color(background, light);
  display: flex;
  flex: 1;

  text-align: center;
  .dark-mode & {
    background: get-color(background, dark) !important;
  }

  &,
  & * {
    color: get-color(secondary, light);
    .dark-mode & {
      color: get-color(secondary, dark);
    }
    font-family: "Siemens Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 28px;
  }

  a {
    font-size: 16px !important;
  }

  @include tablet {
    margin-left: 5rem !important;
  }
  @include smartphone {
    margin-left: 5rem !important;
  }
  @include small-smartphone {
    margin-left: 5rem !important;
  }
}
