@import "colors", "base", "responsive";

aside {
  display: flex;
  flex-direction: column;
  // background: get-color(background, light);
  background: get-color(sidebar, light) !important;

  &.main-sidebar {
    width: $sidebar-width;
  }

  @include small-smartphone {
    &.open {
      width: 100%;
      margin-left: 0 !important;
    }
    overflow-y: visible !important;
  }
  @include smartphone {
    &.open {
      margin-left: 0 !important;
    }
    margin-left: -250px;
    overflow-y: visible !important;
  }
  @include tablet {
    &.open {
      margin-left: 0 !important;
    }
    margin-left: -250px !important;
    overflow-y: visible !important;
  }

  &.open {
    & .sidebar-opener {
      transition: left 0.3s ease-in-out;
      left: 250px;
      right: 0rem;

      @include small-smartphone {
        left: 100%;
      }

      &:after {
        content: "";
      }
    }
  }

  @include desktop {
    width: 20rem;
  }

  .dark-mode & {
    border: 0;
    // background: #090f1c !important;
    background: get-color(sidebar, dark) !important;
  }

  & .sidebar {
    padding: 0px;
    flex: 1 1 100%;

    & .user-panel {
      align-items: center;

      & .image {
        & .img-circle {
          width: 3rem;
        }
      }
    }
  }
  & .sidebar-footer {
    align-self: end;
    display: flex;
    width: 100%;
    padding: 1rem;

    & .versioning {
      padding-left: 6px;
      color: #c2c7d0;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  & .sidebar-opener {
    transition: left 0.3s ease-in-out;
    position: fixed;
    left: 0rem;
    width: 3rem;
    height: 7rem;
    top: 50%;
    color: #fff;
    font-size: 2.5rem;
    background: get-color(elevation-2, dark);
    line-height: 7rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    opacity: 0.5;
    cursor: pointer;

    &:after {
      content: "";
      font-family: "Siemens UI";
      position: absolute;
      right: 3px;
    }

    &:hover {
      opacity: 1;
    }

    @include desktop {
      visibility: hidden;
      display: none;
    }
    @include large {
      visibility: hidden;
      display: none;
    }
  }

  & .nav {
    & .nav-link {
      &.active {
        background-color: get-color(background, light) !important;
        color: get-primary(white) !important;
        &::before {
          content: "";
          position: absolute;
          left: 0px;
          top: 0;
          height: 100%;
          width: 2px;
          background: linear-gradient(180deg, #00ffb9 0%, #00e6dc 100%);
        }

        .dark-mode & {
          background-color: get-color(background, dark) !important;
          color: get-primary(white) !important;
        }
      }
    }
  }
}

aside {
  @include small-smartphone {
    display: none;
  }
  @include smartphone {
    display: none;
  }
  @include tablet {
    display: none;
  }
}

.icon-navbar {
  display: none;
  position: fixed;
  left: 0;
  height: 100%;
  background-color: get-color(elevation-1, dark);
  z-index: 199;
  font-size: 2.5rem;
  overflow: hidden;
  width: $iconsidebar-width;

  transition: 0.25s ease-in-out width;

  @include small-smartphone {
    display: block;
  }
  @include smartphone {
    display: block;
  }
  @include tablet {
    display: block;
  }

  & .sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    & .sidebar-header {
      flex: 1 1 0%;
      padding-top: 1.5rem;
    }
    & .nav-sidebar {
      flex: 1 1 auto;
    }

    & .sidebar-opener {
      position: absolute;
      top: 2rem;
      width: $iconsidebar-width;
      text-align: center;
      cursor: pointer;
      right: unset;
      left: 0;
      z-index: 1;

      transition: 0.2s ease-in-out;

      &:after {
        content: "";
        font-family: "Siemens UI";
      }
    }
  }
  & .nav-link {
    display: flex;
    flex-wrap: nowrap;
    padding: 0.5rem 0;
    // display: flex;
    // justify-content: center;

    & .icon {
      width: $iconsidebar-width;
      display: flex;
      justify-content: center;
    }
    &:hover {
      color: get-primary(white) !important;
    }
    &.active {
      background-color: get-color(primary, dark) !important;
      color: get-color(background, dark) !important;
    }

    & p {
      margin: 0 2rem;
      display: none;
      white-space: nowrap;
    }
  }
  &.open {
    width: 25rem !important;

    & .nav-link {
      // justify-content: flex-start;
      & p {
        display: inline-flex;
        align-self: center;
      }
    }

    & .sidebar-opener {
      // left: 80%;
      &:after {
        content: "";
        font-family: "Siemens UI";
      }
    }
  }

  & .user-panel {
    font-size: 2rem;
    align-items: center;
    margin-top: 4rem;

    & .image {
      width: 5rem;
      height: 90%;
      margin-left: 1rem;
      padding: 0;
      & img {
        height: 90%;
        width: 90%;
      }
    }
    & .info {
      width: 100%;
      height: 100%;
      align-content: center;
      margin: 0 2rem;
      padding: 0;
    }
  }
  & .brand-text h2 {
    width: 100% !important;
    margin-left: 6.3rem !important;
    font-size: 3.5rem !important;
  }
}

.show-onSwipe {
  visibility: hidden !important;

  .open & {
    visibility: visible !important;
  }
}
