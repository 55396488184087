@import "colors", "base";

.card {
  @include unselectable();
  .dark-mode & {
    background: get-color(background, dark);
  }
  & .card-body {
    .dark-mode & {
      background: get-color(nuance, dark);
    }
  }
}

.card.card-primary {
  & .card-header {
    border-radius: 0;
    & h5 {
      margin: 0 !important;
    }
    .dark-mode & {
      background: get-color(hover-2, dark) !important;
      border-bottom: 1px solid get-color(primary, dark);
    }
  }
}
.card.card-secondary {
  & .card-header {
    border-radius: 0;
    .dark-mode & {
      background: get-color(background-alt, dark) !important;
      border-bottom: 1px solid get-color(hover, dark);
    }
  }
}
.info-box {
  background-color: get-color(elevation-1, light);
  .dark-mode & {
    background-color: get-color(elevation-1, dark);
  }
}

.elevation-1 {
  & .info-box {
    background-color: get-color(elevation-2, light) !important;
    .dark-mode & {
      background-color: get-color(elevation-2, dark) !important;
    }
  }
}

.selected {
  cursor: pointer;
  background: get-color(selected, light) !important;
  color: get-primary(deep-blue, base) !important;
  .dark-mode & {
    background: get-color(visited, dark) !important;
    color: get-color(secondary, dark) !important;
  }
}

.drop-container {
  & > form > .row {
    margin: 2rem 0;
    display: flex;
  }
}

.drop-container {
  background: #23233c;
  padding: 2rem 2rem;
  position: relative;
  margin: 1rem 0;

  border: 2px dashed get-color(elevation-2, dark);

  & .title-container {
    margin: 1rem 0;
  }
  & textarea {
    color: #fff;
    height: 25rem !important;
  }

  margin-top: 4rem;
  margin-bottom: 2rem;
  // padding-bottom: 7rem;
  min-height: 7rem;

  & .list-item-container {
    background-color: get-color(elevation-2, light) !important;

    .dark-mode & {
      background-color: get-color(elevation-2, dark) !important;
    }
  }

  & .workplace-title {
    position: absolute;
    top: -2.5rem;
    left: 0;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  .accordion-item & {
    border: 0;
  }
}
