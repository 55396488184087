.viewonly {
  & .content-wrapper {
    padding-top: 0rem !important;
  }

  & .media-player {
    display: flex;
    align-content: center;
    justify-content: center;

    & video {
      //max-width: 50rem;
      max-height: 50rem;
      border-radius: 8px;
    }
  }
}
.content-wrapper.fullwidth {
  margin: 0 10% !important;

  @include tablet {
    margin: 0 5rem !important;
  }
  @include smartphone {
    margin: 0 0rem !important;
  }
  @include small-smartphone {
    margin: 0 0rem !important;
  }
}

.stepper {
  .content-container {
    background-color: get-color(elevation-1, dark);
    padding: 24px 16px;
    border-radius: 8px;
  }

  .image-container {
    max-height: 50rem;
    border-radius: 8px;
    overflow: hidden;
  }
}

.content-container {
  margin-top: 5rem;
  @include smartphone {
    margin-top: 0rem;
  }
  @include small-smartphone {
    margin-top: 0rem;
  }
}

.stepper-container {
  margin: 9rem 0 !important;

  @include smartphone {
    margin: 0rem !important;
  }
  @include small-smartphone {
    margin: 0rem !important;
  }
}

.button-bar {
  z-index: 9;
  margin-top: 5rem;

  & .button-container:nth-of-type(1) {
    flex: 1;
  }

  & .button-container:nth-of-type(2) {
    flex: 1;
    text-align: center;
  }

  & .button-container:nth-of-type(3) {
    flex: 1;
    text-align: right;
  }

  @include smartphone {
    margin-top: 0rem !important;
  }
  @include small-smartphone {
    margin-top: 0rem !important;
  }
  & button {
    display: inline-flex;
  }
}
.content-container,
.button-bar {
  @include small-smartphone {
    margin-left: 8rem !important;
    & div {
      justify-content: space-between;

      .btn-cta {
        margin-left: auto;
      }
      & span ~ .icon {
        padding-right: 0 !important;
        padding-left: 5px;
      }

      & .icon {
        padding-right: 5px !important;
        padding-left: 0px !important;
      }
    }
  }
  @include smartphone {
    margin-left: 8rem !important;
    & div {
      justify-content: space-between;
      & button {
        display: inline-flex;

        .btn-cta {
          margin-left: auto;
        }
        & span ~ .icon {
          padding-right: 0 !important;
          padding-left: 5px;
        }

        & .icon {
          padding-right: 5px !important;
          padding-left: 0px !important;
        }
      }
    }
  }
}

.button-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0 !important;
  margin: 0 !important;
  background: #23233c;
  padding: 1.5rem;

  @include tablet {
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  @include desktop {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  @include large {
    padding-left: 20rem !important;
    padding-right: 20rem !important;
  }
}

.main-header.navbar,
.main-footer {
  .viewonly & {
    margin: 0 !important;
    padding: 0 3rem;
  }

  @include smartphone {
    padding: 0 1rem !important;
  }
  @include small-smartphone {
    padding: 0 1rem !important;
  }
}

.title-row {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.stepper {
  & .media-player video {
    //max-width: 50rem !important;
    max-height: 50rem !important;
  }
  & .title-row {
    & .title {
      text-align: center;
      @include smartphone {
        font-size: 4rem !important;
        line-height: 4.5rem !important;
      }
      @include small-smartphone {
        font-size: 4rem !important;
        line-height: 4.5rem !important;
      }
    }
  }

  & .content-container {
    & img {
      max-width: 100%;
    }
  }
}

.download-container {
  overflow: hidden;
  max-height: 0 !important;
  transition: 0.2s ease-in-out all;
  & .file-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;

    & .icon.type {
      padding-right: 1rem;
    }

    & .title {
      flex: 1;
      align-self: center;
      text-align: left;
    }

    & .icon.download {
      flex: 1;
      text-align: right;
    }
  }
}

.download-container.show {
  margin-top: 2rem;
  max-height: 35rem !important;
  overflow: scroll;
  transition: 0.3s ease-in-out all;

  @include large {
    margin: 0rem 20%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
  @include desktop {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
}
