$primary-palette: (
  siemens-petrol: (
    base: #009999,
  ),
  deep-blue: (
    base: #000028,
    80: #333353,
    60: #66667e,
    40: #9999a9,
    20: #ccccd4,
    10: #e5e5e9,
  ),
  light-sand: (
    base: #f3f3f0,
  ),
  white: (
    base: #fff,
  ),
);

$secondary-palette: (
  green: (
    base: #00af8e,
    dark: #00646e,
    soft: #00d7a0,
    bold: #00ffb9,
    20: rgba(1, 255, 185, 0.2),
    2: rgba(1, 255, 185, 0.02),
  ),
  blue: (
    base: #0087be,
    dark: #00557c,
    soft: #00bedc,
    bold: #00e6dc,
  ),
  basics: (
    transparent: rgba(0, 0, 0, 0),
    white: rgba(255, 255, 255, 1),
    black: rgba(0, 0, 0, 1),
  ),
  toggles: (
    lightest: #ebebee,
    light: #ccccd4,
    dark: #66667e,
  ),
);

$toggle-palette: (
  pin: (
    disabled: #ccccd4,
    off-hover: #262648,
    off: #66667e,
  ),
  background: (
    disabled: #ebebee,
    off: #ccccd3,
    off-hover: #9898a8,
  ),
);

$palette: (
  light: (
    background: get-primary(white),
    background-alt: #dfdfd9,
    elevation-1: #f3f3f0,
    elevation-2: #e8e8e3,
    primary: #0098a6,
    secondary: get-primary(deep-blue, base),
    hover: #00646e,
    hover-2: #62eec7,
    highlight: #01ffb9,
    highlight-2: #d1fff2,
    nuance: #ebf7f7,
    nuance-2: #d1fff2,
    danger: #fcccd7,
    danger-highlight: #d72339,
    success: #01ffb9,
    visited: #73bac9,
    grey: #66667e,
    selected: #007993,
    toggle-bg-disabled: #ebebee,
    toggle-pin-disabled: #ccccd4,
    toggle-bg-off: #7d8099,
    toggle-bg-off-hover: #66667e,
    toggle-pin-off-hover: #ffffff,
    toggle-pin-off: #ffffff,
    toggle-pin-on-hover: #ffffff,
    toggle-bg-on-hover: #005159,
    toggle-bg-on: #005159,
    toggle-pin-on: #ffffff,
    tab-grey: #ccccd4,
    sidebar: #f3f3f0,
    backdrop: #fff,
    input-grey: #000028,
    input-bg: #ebf7f8,
  ),
  dark: (
    background: get-primary(deep-blue, base),
    background-alt: #002949,
    background-alt-2: #23233c,
    elevation-1: #23233c,
    elevation-2: #37374d,
    elevation-3: #474761,
    elevation: (
      1: #23233c,
      2: #37374d,
      3: #474761,
    ),
    primary: #00cccc,
    secondary: get-primary(white),
    hover: #00ffb9,
    hover-2: #001f39,
    highlight: #d1fff2,
    highlight-2: #002038,
    nuance: #001f39,
    danger: #331131,
    danger-highlight: #ff2640,
    success: #00ffb9,
    visited: #007082,
    grey: #9999a9,
    selected: #00ffb9,
    toggle-bg-off: #737389,
    toggle-pin-off: #000028,
    toggle-bg-off-hover: #9999a9,
    toggle-pin-off-hover: #000028,
    toggle-pin-on-hover: #000028,
    toggle-bg-on-hover: #00d49d,
    toggle-bg-on: #00ffb9,
    toggle-pin-on: #000028,
    tab-grey: #737389,
    sidebar: #23233c,
    backdrop: #000028,
    input-grey: #b3b3be,
    input-bg: #00183b,
  ),
);

/* COLOR MIXIN
============================================= */

/* GET COLOR BY NAME AND THEME */
@function get-color($color, $theme) {
  @return map-get(map-get($palette, $theme), $color);
}
//  TODO:
// @function get-toggle-colors($color, $theme) {
//   @return map-get(map-get($palette, $theme), $color);
// }
@function get-secondary($color, $value: base) {
  @return map-get(map-get($secondary-palette, $color), $value);
}

@function get-primary($color, $value: base) {
  @return map-get(map-get($primary-palette, $color), $value);
}

/* GRADIENT MIXIN
============================================= */

@mixin get-gradient($direction, $color-stops...) {
  background: -webkit-linear-gradient($direction, $color-stops) !important;
  background: -o-linear-gradient($direction, $color-stops) !important;
  background: -moz-linear-gradient($direction, $color-stops) !important;
  background: linear-gradient($direction, $color-stops) !important;
}
