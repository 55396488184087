$smartphone-width: 600px;
$tablet-width: 768px;
$desktop-width: 1024px;
$large-width: 1200px;

@mixin small-smartphone {
  @media (max-width: #{$smartphone-width}) {
    @content;
  }
}

@mixin smartphone {
  @media (min-width: #{$smartphone-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) and (max-width: #{$large-width - 1px}) {
    @content;
  }
}

@mixin large {
  @media (min-width: #{$large-width}) {
    @content;
  }
}
