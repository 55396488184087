@import "colors", "base", "texts";

@keyframes scale {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes load {
  0% {
    width: 0;
  }

  10% {
    width: 5%;
  }

  30% {
    width: 25%;
  }

  50% {
    width: 60%;
  }

  60% {
    width: 70%;
  }

  80% {
    width: 90%;
  }

  100% {
    width: 100%;
  }
}

$spinner-size: 1.5rem;
.spinner-container {
  width: calc(100vw - 24rem);
  height: 100vh;
  left: $sidebar-width;
  top: 0;
  position: absolute;
  &.small {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .viewonly & {
    left: 0rem;
    width: 100vw;
  }

  @include tablet {
    width: calc(100vw - $iconsidebar-width);
    left: $iconsidebar-width;
  }

  @include smartphone {
    width: calc(100vw - $iconsidebar-width);
    left: $iconsidebar-width;
  }
  @include small-smartphone {
    width: calc(100vw - $iconsidebar-width);
    left: $iconsidebar-width;
  }

  & .spinner-content {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 10rem;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    transform: translate(-50%, 0);

    & .loader {
      animation: fade 0.95s ease-in infinite alternate;
    }
    & .loader:nth-of-type(2) {
      animation-delay: 0s;
    }
    & .loader:nth-of-type(3) {
      animation-delay: 0.15s;
    }
    & .loader:nth-of-type(4) {
      animation-delay: 0.3s;
    }
    & .loader:nth-of-type(5) {
      animation-delay: 0.45s;
    }
    & .cube {
      width: $spinner-size;
      height: $spinner-size;
      background-color: get-color(primary, light) !important;

      .dark-mode & {
        background-color: get-color(primary, dark) !important;
      }
    }

    & .loading-text {
      text-align: center;
      flex: 1 1 100%;
      font-size: 1.3rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding-bottom: 0.6rem;
    }
  }
}
