@import "colors";
.content {
  & .error-container {
    margin-top: 10%;

    &.centered {
      text-align: center;
    }
    & p.error-code {
      font-size: 15rem !important;
      line-height: 19rem !important;
      position: relative;
      font-weight: 600;

      &::after {
        content: "";
        height: 4px;
        background: get-color(elevation-2, light);
        width: 35rem;
        position: absolute;
        bottom: 1rem;
        left: calc(50% - 35rem / 2);

        .dark-mode & {
          background: get-color(elevation-1, dark);
        }
      }
    }
    & p.error-text {
      font-weight: 100 !important;
      text-transform: uppercase;
      letter-spacing: 0.5rem;
      font-size: 2rem !important;
      color: get-color(elevation-2, light);

      .dark-mode & {
        color: get-color(elevation-3, dark);
        color: get-color(primary, dark);
      }
    }
  }
}
span.error {
  position: absolute;
  left: 0;
  top: 3rem;
  font-weight: 100;
  color: get-color(danger, light);
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;

  inline-size: 100%;
  margin-block-start: 1.5rem;

  margin-bottom: 1rem !important;

  line-height: 1;
}

.input-container {
  position: relative;

  &.error::after {
    content: "!";
    background-color: red;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 1rem;
    top: 50%;
    display: flex;
    justify-content: center;
    font-size: 1rem !important;
    font-weight: 800;
    transform: translate(0, -50%);
    line-height: 1.4;
  }
}
