.preview-box {
  position: relative;
  width: auto;

  &.preview-sm {
    & .preview {
      height: 6rem;
    }
  }
  &.preview-lg {
    width: 20rem;
    height: 20rem;
    margin-left: auto;
    margin-right: auto;

    @include small-smartphone {
      width: 100%;
    }
    @include smartphone {
      width: 100%;
    }

    & .preview {
      position: relative;
      height: inherit;
      width: inherit;
    }
  }
  &.preview-md {
    & .preview {
      position: relative;
      height: 15rem;
      width: auto;
    }
  }

  &-lg img {
    width: 100%;
    height: auto;
  }

  & .preview {
    position: relative;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-content: center;

    & img {
      position: relative;
      max-height: 100%;
      display: flex;
    }
  }

  &.zoomable {
    & .preview {
      &:hover {
        background: rgba(0, 0, 0, 0.4);
        &:after {
          cursor: pointer;
          content: "\e95b";
          position: absolute;
          left: 0;
          font-family: "Siemens UI";
          color: #fff;
          font-size: 4rem;
          z-index: 2;
          top: 0;
          width: 100%;
          text-align: center;
          flex-direction: row;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .noimage {
    border: 2px dashed get-color(elevation-3, dark);
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    line-height: 1;
    text-align: center;

    &:before {
      display: flex;
      align-self: center;
      font-weight: 800;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      line-height: 1.4rem;
      color: get-color(elevation-3, dark) !important;
      content: "no image";
    }
  }
}

.mediafile-preview {
  display: flex;
  flex-direction: column;
  & .image-container {
    width: auto;

    & img {
      width: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }
  & video {
    max-height: 25rem;
    max-width: 100%;
  }
}
