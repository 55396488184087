@import "colors", "base";

.notification-container {
  @include unselectable();
  color: #000028;
  position: fixed;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 20%;
  display: flex;
  border-top-left-radius: 7px;

  & .notification {
    padding: 5px 10px;
    align-items: center;
    display: flex;
  }

  &.success {
    .dark-mode & {
      background: get-color(success, dark);
    }
  }
  &.error {
    .dark-mode & {
      background: get-color(danger-highlight, dark);
    }
  }

  & .notification-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    display: flex;
    &:after {
      color: #000;
      position: absolute;
      content: "";
      font-family: "Siemens UI";
      align-self: center;

      text-align: center;
      width: 100%;
      opacity: 0.5;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}
