@import "colors", "base";
@import "texts", "links", "warnings", "notifications";
@import "buttons", "forms", "tables", "cards", "modal", "switch", "select", "tabs", "listItems", "lists", "accordion";
@import "sidebar", "navbar", "footer";
@import "images", "dnd", "stepper";
@import "extra", "animations", "viewonly", "error", "video", "audio", "files";
@import "responsive";
@import "login";
@import "containers";

.dark-mode {
  background-color: get-color(background, dark) !important;
}
.content-wrapper {
  min-height: calc(100vh - calc(2.5rem + 1px) - calc(2.5rem + 1px)) !important;
  background: get-color(background, light);
  padding: 2rem;
  margin-left: $iconsidebar-width !important;
  margin-right: 0rem;

  @include small-smartphone {
    padding: 0 1rem;
  }

  @include desktop {
    margin-left: calc($sidebar-width + 1rem) !important;
    margin-right: 2rem;
    padding: 2rem;
    padding-top: 8rem;
  }

  @include large {
    margin-left: calc($sidebar-width + 10rem) !important;
    margin-right: 12rem;
    padding: 2rem;
    padding-top: 8rem;
  }

  .dark-mode & {
    background: get-color(background, dark) !important;
  }
}

html {
  font-size: 64.5% !important;
}

body {
  font-size: 1.4rem !important;
  line-height: 1.6 !important;
}

hr {
  border-color: #4f5962 !important;
  margin: 5rem 0;
}
.wrapper {
  position: relative;
  & > div {
    position: inherit;
    min-height: 100vh;
  }
}
.hidden {
  display: none !important;
}
.centered {
  margin-left: auto;
  margin-top: auto;
  margin: auto;
  &-text {
    text-align: center;
  }
}

.fullWidth {
  width: 100% !important;
}

.elevation-2 {
  background: get-color(elevation-2, dark) !important;
}

.hide-onPhone {
  @include small-smartphone {
    display: none !important;
  }
  @include smartphone {
    display: none !important;
  }
}

.hide-onSmallPhone {
  @include small-smartphone {
    display: none !important;
  }
}

#quickfix > * {
  width: 100%;
}

.overlay.fullwidth {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #00002887;
  z-index: 1;
}

.voice-control-container > * {
  margin: 0.5rem;
}
