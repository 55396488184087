@import "colors", "base", "texts";

body:not(.layout-fixed) .main-sidebar .sidebar {
  overflow-y: visible;
}

.ReactFlagsSelect-module_filterBox__3m8EU {
  background: get-color(elevation-2, dark) !important;
  color: #fff;

  & input[type="text"] {
    background: transparent !important;
    color: #fff;
  }
}
#dropdown-basic-button {
  &.dropdown-toggle {
    padding-right: 3rem !important;
  }
}
.dropdown {
  width: 100%;
  & .dropdown-toggle {
    overflow: hidden;
    padding-inline-end: 2rem;

    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    &::after {
      position: absolute;
      right: 1rem;
      top: 50%;
    }
  }
  & span[class*="module_label"] {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &.light > button {
    border-color: get-color(elevation-3, dark);
    color: get-color(secondary, dark) !important;

    line-height: 1.3;
    &:after {
      border-top-color: get-color(secondary, dark) !important;
    }
  }

  #rfs-US {
    & span[class*="selectFlag"] {
      display: none;
    }
  }

  &.languages {
    & button[disabled],
    & button:disabled {
      background: #eaeaea2e;
      &:hover {
        cursor: not-allowed;
      }
      &:after {
        border-top-color: rgba(255, 255, 255, 0.3) !important;
      }
      & > div {
        width: 20rem;
        padding: 0;
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    background: get-color(elevation-2, dark);
    width: 100%;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .dropdown-item {
    padding: 0.8rem 2rem;
    font-size: 1.4rem;
    &:hover {
      .dark-mode & {
        color: get-primary(white) !important;
        background: get-color(elevation-3, dark) !important;
      }
      &:last-of-type {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }
    }
  }
  // language select plugin
  & ul {
    background: get-color(elevation-2, dark) !important;
    border: 0px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    margin: 0;
    max-height: 25rem;
    width: 20rem;
    margin-right: 2rem;
    padding: 0;

    & li {
      padding: 1rem 0.8rem;
      &:hover {
        .dark-mode & {
          color: get-primary(white) !important;
          background: get-color(elevation-3, dark) !important;
        }
      }
    }
  }
}
.search-results {
  position: absolute;
  width: 100%;
  z-index: 10;
  background: #002038;
  padding: 15px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  border: 1px solid #000028;
  border-radius: 0.25rem;
}

.item-select {
  & .form-control {
    height: 4rem;
  }

  & .input-group-append {
    & .btn {
      padding: 0 15px !important;
    }
  }
  & .input-group-prepend {
    & .btn {
      padding: 0 15px !important;
    }
  }
  & .info-box {
    .dark-mode & {
      background: get-color(elevation-2, dark) !important;
    }
    &:hover {
      .dark-mode & {
        background: get-color(elevation-3, dark) !important;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.dropdown.languages {
  & ul {
    width: inherit;
  }
  & ul li > span,
  & button {
    & span:nth-of-type(2) {
      flex: 1 1 100%;
    }
    & span:last-of-type {
      align-self: flex-end;
      flex: 1 1 auto;
      overflow: visible;
      padding: 0 1rem;
    }
  }

  & ul li > span {
    & span {
      padding: 0 1rem;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  & button {
    padding: 5px 0.8rem;
    text-align: start;
    & span:nth-of-type(1) {
      padding-right: 1rem;
    }
  }
  &.open-upward {
    & ul {
      bottom: 4rem;

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
    }

    & > button[class*="module_select"]:after {
      border-bottom: 5px solid #fff !important;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 0 !important;
    }

    & > button[class*="module_select"][aria-expanded="true"]:after {
      border-top: 5px solid #fff !important;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0 !important;
    }
  }
}

.dropdown-inputstyle {
  width: 100%;
  & .dropdown {
    & .dropdown-toggle {
      background: transparent !important;
      color: #fff !important;
      font-size: 1.75rem !important;
      height: 4rem;
      border: 1px solid #000028 !important;
      border-color: #b3b3be !important;
      border-radius: 2px !important;
      text-align: left;
      padding: 0.375rem 0.75rem !important;
      display: block;
      width: 100%;
      position: relative;
      &:hover,
      &::focus {
        background: initial !important;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 1rem;
      }
    }
    & .dropdown-menu {
      width: 100%;
      margin: 0;
      border-radius: 2px;
      & .dropdown-item {
        font-size: 1.75rem !important;
        height: 4rem;
        border-radius: 2px;
        color: get-color(secondary, dark) !important;
        &:hover {
          color: get-color(primary, dark) !important;
          border: 0;
        }
      }
    }
  }
}

#workflows {
  & .languages {
    padding-top: 2.7rem;

    & #rfs-btn {
      height: 4rem;
      border: 1px solid #b3b3be !important;
      border-radius: 2px !important;
    }
  }
}

#organization-selector {
  margin-bottom: 0;
  margin-top: 1rem;
}
